import React from 'react'

const PagesHero = () => {
  return (
    <div id='top' className='Pages-hero' style={{
      marginTop: '-9rem',
      paddingTop: '9rem',
      backgroundColor: 'var(--bg-color-dark)'
      // backgroundImage: `url("https://github.com/reyanjoh/template-literals-imgs/blob/main/bg.jpg?raw=true")`,
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
    }}>
        

    </div>
  )
}

export default PagesHero