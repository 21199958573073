

const NewTitle = (title) => {


    document.title = `Tech&Caffeine | ${title}`;

    // const meta = document.createElement('meta'); 
    // meta.setAttribute('name', 'keywords'); 
    // meta.content="Tech&Caffeineffwfwfwfwfwfwefwefewffefwef";
    // document.querySelector('head').append(meta);

    return null
}

export default NewTitle